.nav.nav-tabs {
  --bs-nav-tabs-border-width: 0;
  --bs-nav-link-padding-x: 0;
  background-color: transparent;
  gap: 1rem;
}

.nav-tabs .nav-link {
  font-size: 12px;
  font-weight: 600;
  line-height: 25px;
}
.nav-tabs .nav-item {
  background: transparent;
}
.nav-tabs .nav-link:not(.active) {
  color: #000;
  opacity: 0.5;
}
.nav-tabs .nav-link:hover {
  color: #555843;
}

.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border-bottom: 2px solid #000;
  border-image: linear-gradient(to right, #000 70%, transparent 70%) 100% 1;
}
