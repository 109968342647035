.custom-btn {
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  min-width: 130px;
  padding: 8px;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  border-radius: 12px;

  font-style: normal;
}
.button-primary {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
}
.button-secondary {
  border: 1px solid #d4d4d4;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  min-width: 130px;
  padding: 8px;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  border-radius: 12px;
  color: #000;
}
.button-secondary:hover {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.16);
}

.button-primary:where(:hover, :focus, :active) :not(:disabled) {
  background-color: #49632b;
  color: #fff;
}
.button-primary:disabled {
  opacity: 0.6;
}

.slide-over-button {
  border: none;
  background: transparent;
  padding: 0;
}
.plain-button {
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
}

.btn-icon {
  border: none;
  background-color: transparent;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  align-items: center;
}
