.slide-over-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid var(--secondary-color);
}
.slide-over-panel .offcanvas-body {
  padding: 15px 20px;
}
.slide-over-header h5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
.slide-over-close-btn {
  border: 1px solid transparent;
  background: transparent;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  padding: 0 7px;
}
.slide-over-close-btn:where(:focus, :active) {
  border: 1px solid var(--secondary-color);
}
