.login-wrapper {
  background-image: url("../../assets/images/login-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: auto;
  min-height: 100vh;
}
img.login-logo {
  max-width: 240px;
  display: block;
  margin: 20px auto 20px;
}
.main {
  display: flex;
  align-items: center;
  padding: 30px 0;
}
.login-wrapper .card {
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.88);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 16px;
  max-width: 600px;
  margin-inline: auto;
}
.card {
  border: none;
}
p {
  font-weight: 600;
  font-size: 15px;
}
.login-wrapper .btn-block {
  background-color: #344a1b;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  min-width: 200px;
  letter-spacing: 1px;
  transition: all 0.4s;
  font-family: "Inter", sans-serif;
  line-height: 47px;
}
.login-wrapper .btn-block:hover,
.login-wrapper .btn-block:active,
.login-wrapper .btn-block:focus {
  background-color: #49632b;
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: #fff;
}
.login-wrapper .form-control {
  margin: 0 auto;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  max-width: 400px;
  min-height: 48px;
}
.login-wrapper :where(.ant-select, .ant-select-selector) {
  margin: 0 auto;
  border-radius: 12px !important;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  max-width: 400px;
  min-height: 48px;
  text-align: start !important;
  border: none !important;
}
.login-wrapper .ant-select-selection-placeholder {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color) !important;
  opacity: 0.8;
}
.login-wrapper .form-group {
  margin-top: 30px;
  position: relative;
}
.login-wrapper .login-title {
  color: #000;
}
.error {
  color: #dc3545;
}
.login-wrapper .form-group .error {
  position: absolute;
  bottom: -25px;
  left: 0;
}
.fab {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #2a284d !important;
  height: 40px;
  width: 90px;
}
.fab:hover {
  cursor: pointer;
}

.division {
  float: none;
  position: relative;
  margin: 30px auto 20px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}
.division .line {
  border-top: 1.5px solid #57557a;
  position: absolute;
  top: 13px;
  width: 85%;
}
.line.l {
  left: 52px;
}
.line.r {
  right: 45px;
}
.division span {
  font-weight: 600;
  font-size: 14px;
}
.myform {
  padding: 0 25px 0 33px;
}
.form-check-input {
  margin-top: 8px !important;
}
.btn-primary {
  border: none;
  border-radius: 50px;
}
.btn-primary:focus {
  box-shadow: none;
  border: none;
}
small {
  color: #f2ceff;
}
.far.fa-user {
  font-size: 13px;
}

@media (min-width: 767px) {
  .bn {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .form-check {
    text-align: center;
  }
  .bn {
    text-align: center;
    align-items: center;
  }
}
@media (max-width: 450px) {
  .fab {
    width: 100%;
    height: 100%;
  }
  .division .line {
    width: 50%;
  }
}
