.toast-message {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
  opacity: 0.7;
  border-radius: 12px;
  padding: 12px 16px;
  min-width: 250px;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toast-message button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 5px;
  color: #fff;
}
.toast-message:not(.active) {
  display: none;
}
