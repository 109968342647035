/*----- Common Css -----*/
:root {
  --blue-color: #252037;
  --pink-color: #e03a6a;
  --light-color: #a7a5ae;
  --white-color: #e2e2e2;
  --yellow-color: #f1e246;
  --light-dark: #5f666d!;
}
.header-spacer {
  margin-top: 40px;
}
.program-steps-card {
  border: 1px solid #e4e4e4;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.16);
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
}
.btn.btn-primary {
  background-color: #344a1b;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  min-width: 150px;
  padding: 14px 16px;
  transition: all 0.4s;
  line-height: 16px;
}
.program-steps-card .form-group {
  margin-bottom: 30px;
  position: relative;
}
.program-steps-card .form-control {
  margin-bottom: 0px;
}
.program-steps-card label {
  font-weight: 600;
  font-size: 16px;
}
.program-steps-card .error {
  position: absolute;
  left: 0;
  bottom: -25px;
  font-weight: normal;
}
.add-btn .add-new {
  background-color: #344a1b;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  min-width: 130px;
  padding: 12px 8px;
  transition: all 0.4s;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: center;
}
.table_common thead tr {
  background-color: #fffff0;
}
.table_common thead tr th {
  color: #000;
}
.add-btn .add-new i {
  margin-inline-end: 5px;
}
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.add-btn .add-new:hover,
.add-btn .add-new:active,
.add-btn .add-new:focus {
  background-color: #49632b;
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: #fff;
}
.light_color {
  color: var(--light-color);
}
.light_dark {
  color: var(--light-dark);
}
img {
  width: 100%;
  object-fit: cover;
}
a:hover {
  text-decoration: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}
h3 {
  font-weight: 600;
}
h6 {
  color: var(--white-color);
}
ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.container {
  max-width: 1366px;
  width: 100%;
}
.btn {
  font-weight: 600;
  padding: 0 16px;
  font-size: 15px;
  line-height: 38px;
  border-radius: 50px;
}
.btn:hover {
  color: #fff;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn_outline {
  color: var(--pink-color);
  border: 1px solid var(--pink-color);
}
.btn_pink {
  background: var(--pink-color);
  color: #fff;
}
.btn_white {
  background: #fdf0f3;
  color: #111;
}
.btn_white:hover {
  color: #111;
}
.codedruns_outer {
  max-width: 1920px;
  width: 100%;
  display: inline-block;
}
.sortCheck {
  position: relative;
}
.sortCheck .fa-arrow-down {
  display: none;
}
.sortCheck input {
  opacity: 0;
  position: absolute;
}
.sortCheck input:checked ~ .fa-arrow-up {
  display: none;
}
.sortCheck input:checked ~ .fa-arrow-down {
  display: block;
}

.escort-pictures-listing .button_tab_wrapper {
  overflow-x: auto;
}
.common-create-modal .modal-content {
  background: #fff;
}
.common-create-modal .btn {
  border-radius: 12px;
}
div#loadPaymentHistory .modal-content {
  background: #fff !important;
}
div#loadPaymentHistory h5.modal-title {
  font-size: 1.25rem;
}
div#loadPaymentHistory .modal-content img {
  width: 19px !important;
  color: #222 !important;
}

div#loadPaymentHistory .modal-header {
  color: #333;
}
.sortCheck2 {
  font-size: 13px;
  background: var(--blue-color);
  color: var(--light-color);
  border-radius: 7px;
  margin: 0px 16px 16px 0px;
}
.tab_link {
  position: relative;
}
.tab_link input {
  opacity: 0;
  position: absolute;
}
.tab_link input:checked + .sortCheck2 {
  background: var(--yellow-color);
  color: var(--blue-color);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.m-l-advance-search > button,
.m-l-advance-search > button:focus {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  background-color: #333;
  color: var(--theme-font-color);
  border: 0;
  height: 40px;
  position: relative;
}
.row.escortDetails h5 strong {
  font-size: 14px;
  color: #928f9b;
  display: inline-block;
  min-width: 170px;
}

.row.escortDetails h5 {
  border-bottom: 1px solid #3c3558;
  padding: 5px;
  font-size: 14px;
}
hr {
  border-top: 1px solid #3f3e3e;
}
.rating {
  color: var(--yellow-color);
}
.white_bg {
  background: #f9f9f9;
}
.color_yellow {
  color: var(--yellow-color) !important;
}
.blue_color {
  color: var(--blue-color);
}
.pink_color {
  color: var(--pink-color) !important;
}
.max-w {
  max-width: 100% !important;
}
.sortCheck {
  position: relative;
}
.sortCheck .fa-arrow-down {
  display: none;
}
.sortCheck input {
  opacity: 0;
  position: absolute;
}
.sortCheck input:checked ~ .fa-arrow-up {
  display: none;
}
.sortCheck input:checked ~ .fa-arrow-down {
  display: block;
}

.sortCheck2 {
  font-size: 13px;
  background: var(--blue-color);
  color: var(--light-color);
  border-radius: 7px;
  margin: 0px 16px 16px 0px;
}

.tab_link {
  position: relative;
}

.tab_link input {
  opacity: 0;
  position: absolute;
}
.tab_link input:checked + .sortCheck2 {
  background: var(--yellow-color);
  color: var(--blue-color);
}

.m-l-advance-search > button,
.m-l-advance-search > button:focus {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  background-color: #333;
  color: var(--theme-font-color);
  border: 0;
  height: 40px;
  position: relative;
}
.row.escortDetails h5 strong {
  font-size: 14px;
  color: #928f9b;
  display: inline-block;
  min-width: 170px;
}

.row.escortDetails h5 {
  border-bottom: 1px solid #3c3558;
  padding: 5px;
  font-size: 14px;
}
img.coins {
  width: 20px;
}

table.table.payment-table {
  background: white;
}

/*----- Left icon bar -----*/
.codedruns_outer .let_menu_bar {
  background: var(--blue-color);
  width: 70px;
  float: left;
  position: fixed;
  top: 0;
  height: 100%;
  text-align: center;
  overflow-y: auto;
}
.let_menu_bar::-webkit-scrollbar {
  display: none;
}
.let_menu_bar {
  -ms-overflow-style: none;
}
.let_menu_bar ul li.first_menu {
  padding: 0px 5px;
  color: #fff;
  margin-bottom: 20px;
  height: 64px;
  line-height: 64px;
}
.let_menu_bar ul li.first_menu img {
  width: 40px;
}
.let_menu_bar ul li a {
  color: #999;
  padding: 12px 5px;
  border-left: 3px solid transparent;
  width: 100%;
  display: block;
  position: relative;
}
.let_menu_bar ul li a .badge-dark {
  position: absolute;
  right: 14px;
  top: 5px;
  background-color: #e03a6a;
}
.let_menu_bar ul li a img {
  width: 24px;
  opacity: 0.5;
}
.let_menu_bar ul li.active a,
.let_menu_bar ul li:hover a {
  border-left-color: #f1e246;
  background: #302a47;
}
.let_menu_bar ul li.active a img,
.let_menu_bar ul li:hover a img {
  opacity: 1;
}
.toggle_nav,
.let_menu_bar ul li.first_menu.close_btn {
  display: none;
}

/*----- Right Content Main -----*/
.codedruns_outer .right_content {
  width: calc(100% - 70px);
  float: right;
  display: block;
}

/*----- Top Header -----*/
.codedruns_outer .right_content .top_header {
  position: fixed;
  top: 0;
  background: var(--blue-color);
  width: calc(100% - 70px);
  padding: 10px 30px;
  display: inline-block;
  z-index: 2;
}
.top_button {
  width: calc(100% - 600px);
  float: right;
  text-align: right;
  margin-top: 5px;
}
.top_button .login_user_img {
  width: 40px;
  display: inline-block;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ddd;
}
.top_button .login_user_img img {
  border-radius: 50%;
}
.mobile_view {
  display: none;
}

/*------ Content Section -----*/
.main_content {
  margin-top: 63px;
  padding: 32px 20px 20px;
  min-height: 520px;
}
.card_row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}
.card_five,
.card_four {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.card_five {
  flex: 0 0 20%;
  max-width: 20%;
}
.card_four {
  flex: 0 0 25%;
  max-width: 25%;
}
.card_detail {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.card_img {
  position: relative;
  width: 100%;
  display: inline-block;
  height: 315px;
  border-radius: 8px;
}
.card_detail img {
  height: 315px;
  border-radius: 8px;
}
.card_img .card_btn {
  position: absolute;
  bottom: 0px;
  padding: 10px;
  width: 100%;
}
.flag_btn {
  position: absolute;
  top: 0px;
  padding: 10px;
  width: 100%;
  text-align: left;
}
.flag_btn img {
  width: 24px;
  height: 24px;
}
.fresh_tag {
  position: absolute;
  top: -5px;
  right: -6px;
}
.fresh_tag img {
  width: auto;
  height: auto;
}
.card_img .card_bottom_text {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 8px 8px;
  padding: 10px;
  display: block;
}
.card_img .card_bottom_text h3 {
  font-weight: 500;
}
.card_img .card_bottom_text p {
  color: #fff;
}
.card_btn .btn {
  background: linear-gradient(to bottom, #5bc76f 0%, #53b765 100%);
  border: none;
  line-height: 30px;
  padding: 0 14px;
  width: auto;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
.card_btn .new_btn {
  background: linear-gradient(to bottom, #fe53b2 0%, #fe1f9a 100%);
}
.card_btn .like_btn {
  background: var(--pink-color);
  float: right;
  border-radius: 50%;
  font-size: 18px;
  height: 34px;
  width: 34px;
  padding: 0;
  line-height: 36px;
}
.card_bottom_text {
  display: inline-block;
  width: 100%;
  color: #fff;
  padding: 10px 0;
}
.card_bottom_text h3 {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 24px;
}
.card_bottom_text h3 small,
.card_bottom_text h3 span {
  font-size: 70%;
  white-space: nowrap;
}
.card_bottom_text h3 span {
  color: var(--yellow-color);
}
.card_bottom_text h3 small i,
.card_bottom_text h3 span i {
  color: var(--yellow-color);
  margin-right: 4px;
}
.card_bottom_text p {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  overflow: hidden;
  margin-top: 5px;
  color: var(--light-color);
}
.edit_picks .card_img {
  height: 215px;
}
.edit_picks .card_detail img {
  height: 215px;
}
.edit_picks .card_img .card_btn,
.edit_pick_details .card_img .card_btn {
  top: 0;
  text-align: right;
}
.edit_picks .card_img .card_btn .time_btn,
.edit_pick_details .card_img .card_btn .time_btn {
  background: #724187;
  border-radius: 6px;
  line-height: 27px;
  padding: 0 10px;
}

/*----- Rooms & Shop Pages -----*/
.shop_page .card_img,
.rooms_let .card_img {
  height: 240px;
}
.shop_page .card_detail .card_img img,
.rooms_let .card_detail .card_img img {
  height: 240px;
}
.card_bottom_text h4 {
  font-size: 13px;
  color: var(--yellow-color);
  margin-top: 6px;
}
.card_bottom_text h6 {
  margin: 7px 0;
  font-size: 12px;
}
.card_bottom_text h6 i {
  font-size: 14px;
  margin-right: 4px;
}
.shop_img_text {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

/*----- Review -----*/
.review_rating {
  font-size: 12px;
  margin: 5px 0;
}
.review_rating i {
  color: var(--yellow-color);
}
.review_rating span {
  color: #999;
  margin-left: 5px;
}

/*----- All Event -----*/
.date_bg {
  background: var(--pink-color);
  width: 62px;
  height: 62px;
  text-align: center;
  padding: 12px 0;
  font-size: 24px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 4px;
  float: left;
  margin-right: 8px;
}
.date_bg span {
  font-size: 16px;
  font-weight: 400;
}
.date_text {
  float: left;
  width: calc(100% - 70px);
}
.all_event .card_img .card_btn {
  top: 0;
  text-align: right;
  padding: 0;
}
.all_event .card_img .card_btn .time_btn {
  background: rgb(114, 65, 135, 0.8);
  border-radius: 0px 6px 0px 6px;
}
.all_event .event_price {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #131419;
  color: var(--yellow-color);
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 10px 0 0 0;
}
.edit_pick_details .card_detail .card_img {
  height: 470px;
}
.edit_pick_details .card_detail .card_img img {
  height: 470px;
}
.video_bottom_icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--blue-color);
  margin-top: 16px;
  border-radius: 7px;
}
.video_bottom_icon a {
  width: 100%;
  text-align: center;
  padding: 14px 5px 10px;
  border-left: 2px solid #000;
}
.video_bottom_icon a img {
  width: 24px;
  margin-bottom: 6px;
}
.edit_pick_details .card_detail .card_img img.play_icon {
  width: 72px;
  height: 72px;
  position: absolute;
  left: 46%;
  top: 43%;
}
.video_bottom_icon a:first-child {
  border-left: none;
}
.video_bottom_icon a span {
  color: #f9dde5;
}
.description h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.description p {
  margin-bottom: 12px;
}
.posted_by img {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 10px;
  border-radius: 8px;
}
.posted_by .posted_by_text h4 {
  font-size: 16px;
  margin-bottom: 5px;
}
.posted_by .posted_by_text p {
  font-size: 14px;
  color: var(--light-color);
}
.product_right_detail h2 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 8px;
}
.product_right_detail span {
  margin: 7px 0 0;
  display: inline-block;
}

.product_right_detail p {
  font-weight: 600;
  margin-top: 24px;
}
.whatsapp_logo img {
  width: 32px;
  margin-right: 10px;
}
.list_des li {
  margin-bottom: 5px;
}
ul.list_des li::before {
  content: "";
  background: var(--pink-color);
  font-weight: bold;
  display: inline-block;
  width: 8px;
  margin-right: 10px;
  height: 8px;
  border-radius: 50%;
  vertical-align: 3px;
}

/*----- Claaified Ads -----*/
.classified_bg {
  background: var(--blue-color);
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}
.classified_ads {
  display: flex;
  align-items: center;
}
.classified_img {
  width: 116px;
  float: left;
  margin-right: 20px;
  border-radius: 8px;
  height: 124px;
}
.classified_img img {
  height: 124px;
  border-radius: 8px;
}
.classified_detail {
  width: calc(100% - 170px);
  float: left;
}
.classified_detail h3,
.classified_text h3 {
  font-size: 18px;
  margin-bottom: 5px;
}
.classified_detail p {
  font-size: 13px;
  margin-top: 4px;
  color: var(--light-color);
}
.classified_text {
  display: inline-block;
  padding-top: 20px;
  width: 100%;
}
.classified_text h6,
.classified_detail h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #dadada;
}
.heart_icon {
  background-image: linear-gradient(90deg, #ff3535, #ff7777, #ff3535);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.classified_text_withbg {
  background: #fff;
  margin-top: 16px;
  padding: 16px;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
}
/* Pagination Design CSS Start */
.pagination-wrap {
  width: 100%;
  text-align: center;
}
.pagination-wrap
  .ngx-pagination
  .disabled
  .pagination-wrap
  .ngx-pagination
  .current {
  padding: 0;
}
.pagination-wrap li a {
  padding: 0.75rem 1rem;
  display: inline-block;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.pagination-wrap .ngx-pagination {
  margin: 15px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-wrap .ngx-pagination .current > span {
  padding: 0.75rem 1rem;
  display: inline-block;
  margin-left: -1px;
  line-height: 1.25;
  color: #fff;
  background-color: #007bff;
  border: 1px solid #007bff;
}
.pagination-wrap a:not([href]):hover,
.pagination-wrap a:not([href]):not([class]) {
  color: #007bff;
}
.pagination-wrap .pagination-previous,
.pagination-wrap .pagination-next {
  position: relative;
}
.pagination-wrap .pagination-previous > span,
.pagination-wrap .pagination-previous a,
.pagination-wrap .pagination-next > span,
.pagination-wrap .pagination-next > a {
  font-size: 0px;
  padding: 23px;
  line-height: 0.5;
  background-color: #fff;
  border: 1px solid #dee2e6;
  box-shadow: 0px 0px 3px #fff;
  position: relative;
  display: inline-block;
}
.pagination-wrap .ngx-pagination .disabled {
  padding: 0;
}
.pagination-wrap .ngx-pagination .current {
  padding: 0;
}
.pagination-wrap .pagination-previous > span,
.pagination-wrap .pagination-previous a,
.pagination-wrap .pagination-next > span,
.pagination-wrap .pagination-next > a {
  font-size: 0px;
  padding: 23px;
  line-height: 0.5;
  background-color: #fff;
  border: 1px solid #dee2e6;
  box-shadow: 0 0 3px #fff;
  position: relative;
  display: inline-block;
}
.pagination-wrap .pagination-previous a,
.pagination-wrap .pagination-next a {
  transition: all 0.4s;
}
.pagination-wrap .pagination-previous > span,
.pagination-wrap .pagination-previous a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination-wrap .pagination-next > span,
.pagination-wrap .pagination-next a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination-wrap .ngx-pagination .pagination-previous a:before,
.pagination-wrap .ngx-pagination .pagination-previous.disabled:before {
  content: "\‹";
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 24px;
}
.pagination-wrap .ngx-pagination .pagination-next a:before,
.pagination-wrap .ngx-pagination .pagination-next.disabled:before {
  content: "\›";
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 24px;
}
.pagination-wrap .pagination-previous a,
.pagination-wrap .pagination-next a {
  transition: all 0.4s;
}
.pagination-wrap .pagination-previous a:hover,
.pagination-wrap .pagination-next a:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  transform: scale(1.01);
}
.pagination-wrap .pagination-previous > span,
.pagination-wrap .pagination-previous a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination-wrap .pagination-next > span,
.pagination-wrap .pagination-next a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination-wrap .ngx-pagination .pagination-next a:after,
.pagination-wrap .ngx-pagination .pagination-next.disabled:after {
  display: none;
}
.dashboard_box .btn {
  display: inline-block;
  margin-bottom: 12px;
}
/* Pagination Design CSS End */
.btn-red {
  background: red !important;
  border-color: red !important;
  color: #fff !important;
}

.btn-black {
  background: #222 !important;
  border-color: #222 !important;
  color: #fff !important;
}

.btn-green {
  background: green !important;
  border-color: green !important;
  color: #fff !important;
}

.btn-yellow {
  background: yellow !important;
  border-color: yellow !important;
  color: #fff !important;
}

.btn-pink {
  background: pink !important;
  border-color: pink !important;
  color: #fff !important;
}
.btn-blue {
  background: blue !important;
  border-color: blue !important;
  color: #fff !important;
}
.btn-white {
  background: #fff !important;
  border-color: #fff !important;
  color: #000 !important;
}

a.btn.blue_outline.edit_btn.btn-block.btn-green {
  color: #fff !important;
  font-weight: 700 !important;
}

a.btn.blue_outline.edit_btn.btn-block.btn-white {
  color: #111 !important;
  font-weight: 700 !important;
}

a.btn.make_escorts_blacklisted.pink_outline.edit_btn.btn-block.btn-black {
  color: #fff !important;
  font-weight: 700;
}

a.view-popup-image.btn.blue_outline.btn-block.btn-yellow {
  color: #252037 !important;
}

a.btn.blue_outline.btn-block.make_escorts_private.btn-pink {
  color: #111 !important;
  font-weight: 700 !important;
}

a.btn.delete-escort-recort.pink_outline.edit_btn.btn-block.btn-yellow {
  color: #111 !important;
  font-weight: 700 !important;
}

a.btn.delete-escort-recort.pink_outline.edit_btn.btn-block.btn-red {
  color: #fff !important;
  font-weight: 700 !important;
}
.table_common.user-dashboard-table .table ~ .pagination {
  padding-bottom: 15px;
  padding-left: 15px;
}
.secure-escort-listing .table_common .table #getData .classified_text_withbg {
  width: auto;
}
.secure-escort-listing .table_common .table #getData h5 {
  color: #fff;
}
.secure-escort-listing .table_common .table #getData .classified_text_withbg p {
  color: #000;
}
.classified_text_withbg h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: var(--blue-color);
}
.classified_text_withbg h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #343a40;
}
.detail-right-btns {
  min-width: 320px;
}
.right-detail-wrap {
  display: flex;
}
.right-detail-wrap .product_right_detail.new_cs {
  width: calc(100% - 360px);
  padding-right: 20px;
}
.product_right_detail h2 a {
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -30px;
  position: relative;
  top: -5px;
}
/*----- Shop Product Details With Product -----*/
.edit_pick_details.shop_product_detail_tab .card_detail .card_img img {
  height: 320px;
}
.edit_pick_details.shop_product_detail_tab .card_detail .card_img {
  height: 320px;
}
.shop_porduct_btn button.btn {
  background: #fe9433;
  padding: 0 6px;
  line-height: 23px;
  font-size: 12px;
  max-width: 65px;
  width: 100%;
  margin-top: 16px;
  font-weight: 500;
  color: #fff;
}
.shop_porduct_btn button.btn:nth-child(2),
.shop_porduct_btn button.btn:nth-child(4) {
  background: #f543b3;
}
.shop_porduct_btn button.btn:nth-child(3) {
  background: #5bc66f;
}
.shop_porduct_btn button.btn:nth-child(5) {
  background: #fd2b2b;
}
.weight_height .weight_height_box {
  border-right: 1px solid var(--pink-color);
  width: 100%;
  text-align: center;
  padding: 12px 5px;
}
.weight_height {
  display: flex;
  width: 100%;
  border: 1px solid var(--pink-color);
  max-width: 450px;
  border-radius: 8px;
  margin-top: 24px;
}
.weight_height .weight_height_box:last-child {
  border-right: 0;
}
.weight_height .weight_height_box h5 {
  font-size: 26px;
  color: #eee;
  font-weight: 600;
}
.shop_custom_tab .nav-tabs {
  border-bottom: none;
  margin-bottom: -4px;
}
.shop_custom_tab .nav-tabs .nav-link {
  border: none;
  padding: 18px 12px 22px;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  font-weight: 600;
  width: 200px;
  text-align: center;
  color: var(--light-color);
}
.shop_custom_tab .nav-tabs .nav-item.show .nav-link,
.shop_custom_tab .nav-tabs .nav-link.active {
  color: #fff;
  background-color: var(--blue-color);
  border: none;
}
.shop_tab_newchanges .nav-tabs .nav-item.show .nav-link,
.shop_tab_newchanges .nav-tabs .nav-link.active {
  color: var(--pink-color);
}
.shop_custom_tab .tab-content {
  background: var(--blue-color);
  padding: 20px;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
}
ul.about_details {
  display: inline-block;
  width: 100%;
}
.about_details li {
  width: 25%;
  float: left;
  margin-bottom: 20px;
  padding-right: 10px;
}
.about_details li p {
  color: var(--light-color);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 5px;
}
.shop_tab_newchanges .about_details li p {
  color: var(--pink-color);
}
.about_details li h6 {
  font-size: 14px;
  font-weight: 400;
}
.about_rate {
  display: inline-block;
  width: 100%;
  border-top: 1px solid var(--pink-color);
  padding-top: 20px;
}
.about_rate h4 {
  font-size: 20px;
}
.about_rate h5 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
}
.about_rate h5 span {
  color: var(--light-color);
  width: 140px;
  display: inline-block;
}
ul.service_details {
  display: inline-block;
  width: 100%;
}
.service_details li {
  width: 33.33%;
  float: left;
  margin-bottom: 12px;
  color: var(--light-color);
}
ul.service_details li::before,
.subscription_detail ul li::before {
  content: "";
  background: var(--pink-color);
  font-weight: bold;
  display: inline-block;
  width: 6px;
  margin-right: 8px;
  height: 6px;
  border-radius: 50%;
  vertical-align: 3px;
}
#reviews .main_heading .heading_title {
  font-size: 18px;
}
.modal-body .wallet_price.mb-3.color_yellow {
  display: block;
}
.table.payment-table thead th {
  min-width: 100px;
}
.payment-table tbody tr.coinsAdded {
  background-color: #9abdf1;
}
.payment-table tbody tr.coinSpent {
  background-color: #ecc0c4;
}
/* Review Page New design Start */
.review-table .row.escortDetails h5 {
  border-bottom: 1px solid #3c3558;
  padding: 5px;
  font-size: 14px;
}
.review-table .row.escortDetails h5 strong {
  font-size: 14px;
  color: #928f9b;
  display: inline-block;
  min-width: 170px;
}
/* Review Page New  design End */
/* FAQ Design Start */
.accordiaon-main .card-body {
  background-color: #ededef;
}
.accordiaon-main .card-header {
  background-color: #fff;
}
.faq-page .dashboard_title a {
  margin-bottom: 0;
  color: #fff;
}
.faq-accord-title h3 {
  font-size: 22px;
  margin: 30px 0 20px;
}
.accordiaon-main .card-header .card-link {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.accordiaon-main .card-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordiaon-main .card-header i {
  color: #e03a6a;
  transition: all 0.4s;
}
.accordiaon-main .card-body {
  color: #000;
}
.accordiaon-main .card-body p {
  max-width: 700px;
  padding-left: 25px;
}
.accordiaon-main .card {
  margin-bottom: 25px;
}
.faq-page .heading_title {
  margin-bottom: 10px;
}
.accordiaon-main .card:first-child .card-link ~ i {
  transform: rotate(180deg);
}
.accordiaon-main .card:first-child .card-link ~ i {
  transform: rotate(180deg);
}
.accordiaon-main .card:first-child .card-link[aria-expanded="false"] ~ i {
  transform: rotate(0deg);
}
.accordiaon-main .card-link[aria-expanded="true"] ~ i {
  transform: rotate(180deg);
}
.reset-template .img-resend {
  width: auto;
  margin-bottom: 20px;
}
.modal-dialog.reset-dialog {
  max-width: 400px;
  width: 85%;
}
.reset-dialog .template-content p:not(.subtitle) {
  font-size: 16px;
}
.reset-dialog .btn-resend {
  background-color: #e03a6a;
  color: #fff;
  border-radius: 50px;
  width: 85%;
  display: inline-block;
  padding: 10px;
  text-transform: capitalize;
  margin-top: 60px;
  max-width: 300px;
}
.reset-dialog .btn-resend:hover {
  background-color: #c22956;
}
.resend-mail a {
  color: #c22956;
  text-transform: capitalize;
  font-weight: 600;
}
.reset-template .close {
  position: absolute;
  right: -8px;
  top: -8px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 6px #222;
  padding: 3px;
  background-color: #fff;
  opacity: 1;
}
.reset-template .img-cross {
  width: auto;
  max-width: 10px;
}
.edit-section .common-edit {
  font-size: 20px;
  padding: 2px 9px;
  border-radius: 6px;
  display: inline-block;
}
.edit-section .edit-faq {
  background-color: #e03a6a;
}
.edit-section .delete-faq {
  background-color: #dc3545;
  margin-left: 10px;
}
.edit-section .common-edit i {
  color: #fff;
}
.secure-escort-listing .wrap-title .dashboard_title_btn {
  max-width: 50px;
  width: 100%;
}
.secure-escort-listing .wrap-title .dashboard_title {
  max-width: calc(100% - 60px);
}
/* FAQ Design End */
/*----- Profile -----*/
.porfile_outer .card_detail,
.edit_profile_outer .card_detail {
  margin-top: 0px;
}
.profile_menu_outer {
  padding-right: 24px;
}
.profile_menu li {
  padding: 14px 16px;
  border-radius: 50px;
  color: var(--white-color);
}
.profile_menu li a {
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_menu li.active {
  background: var(--pink-color);
  font-weight: 600;
}
.profile_white_bg {
  margin: 10px 0px 20px;
  padding: 20px;
  border-radius: 8px;
}
.profile_white_bg h6 {
  font-size: 14px;
  margin-bottom: 6px;
  color: #999;
}
#gallery .card_detail {
  margin: 0 0 24px;
}
#gallery .card_detail .card_img,
#gallery .card_detail .card_img img {
  height: 140px;
}
#gallery .nav-tabs {
  border-bottom: none;
  margin-bottom: 2px;
}
#gallery .nav-tabs .nav-item {
  margin-right: 10px;
}
#gallery .nav-tabs .nav-item .nav-link {
  font-weight: 600;
  padding: 0 16px;
  font-size: 15px;
  line-height: 38px;
  border-radius: 50px;
  width: 100%;
  background: var(--white-color);
  color: #111;
}
#gallery .nav-tabs .nav-item.show .nav-link,
#gallery .nav-tabs .nav-link.active {
  color: #fff;
  background-color: var(--pink-color);
}

/*----- Switch Toggle -----*/
.switch_custom {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  margin-bottom: 0;
}
.switch_custom input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch_custom .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.switch_custom .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.switch_custom input:checked + .slider {
  background-color: var(--blue-color);
}
.switch_custom input:focus + .slider {
  box-shadow: 0 0 1px var(--blue-color);
}
.switch_custom input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/*----- My Video -----*/
.my_video_page .card_detail {
  margin-bottom: 16px;
}
.my_video_page .card_detail .card_bottom_text .view_and_earning {
  margin-top: 8px;
  display: inline-block;
  font-size: 14px;
}
.add_video_page form .btn {
  width: 120px;
}
.add_video_page form .btn_white {
  background: #ccc;
}
.add_video_page .about_details li {
  margin-bottom: 36px;
}
.custom_file_upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
.custom_file_upload input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.add_video_page .shop_custom_tab .form-control {
  background: var(--blue-color);
  border: none;
  color: #fff;
  border-radius: 30px;
  border: 2px solid var(--pink-color);
  padding: 9px 10px;
  font-size: 14px;
  height: 44px;
}
.add_video_page .shop_custom_tab textarea.form-control {
  height: auto;
}

/*----- Footer -----*/
.footer {
  background: #131419;
  width: 100%;
  color: #fff;
  position: relative;
  bottom: 0;
  z-index: 3;
  padding: 16px 15px;
  top: auto;
  left: 0;
  box-shadow: -3px -3px 7px 0px #000;
}
.footer_socil_link {
  margin-left: 90px;
}
.footer_socil_link a {
  color: #fff;
  margin: 0 12px;
  font-size: 24px;
}

/*----- Custom Radio & Checkbox -----*/
.custom_radio {
  margin-top: 9px;
}
.custom_radio [type="radio"]:checked,
.custom_radio [type="checkbox"]:checked,
.custom_radio [type="radio"]:not(:checked),
.custom_radio [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.custom_radio [type="radio"]:checked + label,
.custom_radio [type="checkbox"]:checked + label,
.custom_radio [type="radio"]:not(:checked) + label,
.custom_radio [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #111;
}
.custom_radio [type="radio"]:checked + label:before,
.custom_radio [type="checkbox"]:checked + label:before,
.custom_radio [type="radio"]:not(:checked) + label:before,
.custom_radio [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 100%;
  background: transparent;
}

.custom_radio [type="checkbox"]:checked + label:before,
.custom_radio [type="checkbox"]:not(:checked) + label:before {
  border-radius: 5px;
}

.custom_radio [type="radio"]:checked + label:after,
.custom_radio [type="checkbox"]:checked + label:after,
.custom_radio [type="radio"]:not(:checked) + label:after,
.custom_radio [type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #f87da9;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom_radio [type="checkbox"]:checked + label:after,
.custom_radio [type="checkbox"]:not(:checked) + label:after {
  border-radius: 5px;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  /* background-image: url(../../assets/images/tick.png); */
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
}
.custom_radio [type="radio"]:not(:checked) + label:after,
.custom_radio [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.custom_radio [type="radio"]:checked + label:after,
.custom_radio [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*----- Dashboard Header -----*/
#dashboard_header.codedruns_outer .right_content .top_header {
  width: calc(100% - 200px);
}
#dashboard_header.codedruns_outer .right_content {
  width: calc(100% - 200px);
}
#dashboard_header.codedruns_outer .let_menu_bar {
  width: 200px;
}
#dashboard_header .dashboard_logo {
  padding: 24px 30px;
}
#dashboard_header .let_menu_bar ul li a {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font-size: 14px;
}
#dashboard_header .let_menu_bar ul li a img {
  margin-right: 10px;
}
#dashboard_header .let_menu_bar ul li.active a,
#dashboard_header .let_menu_bar ul li:hover a {
  color: #fff;
}
.top_button span {
  color: var(--white-color);
  margin-right: 5px;
}
a.notificaion_icon {
  margin-right: 24px;
  position: relative;
}
.notificaion_icon img {
  width: 25px;
}
a.notificaion_icon:after {
  position: absolute;
  content: "";
  background: #0ce60c;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 0;
  right: 0;
  border: 1px solid #111;
}
.dashboard_content {
  padding: 16px 20px 20px;
}

/*----- Dashboard Page -----*/
.dashboard_box {
  padding: 20px 20px 16px;
  background: var(--blue-color);
  border-radius: 5px;
  margin-bottom: 16px;
}
.dashboard_box p {
  color: var(--white-color);
  margin-bottom: 5px;
  font-size: 14px;
}
.dashboard_box h4 {
  font-size: 24px;
}
.dashboard_box i {
  background: #999;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 5px;
  font-size: 20px;
}

/*----- Dashboard Product Page -----*/
.dashboard_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  flex-flow: wrap;
  background: var(--blue-color);
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.dashboard_title {
  display: flex;
  align-items: center;
  width: calc(100% - 270px);
  flex-flow: wrap;
}
.dashboard_heading.btns-title .dashboard_title {
  width: 100%;
}
.dashboard_title h2 {
  font-size: 20px;
  font-weight: 600;
}
.dashboard_title a {
  font-size: 20px;
  color: var(--light-color);
  margin-right: 40px;
  font-weight: 600;
  transition: all 0.4s;
  position: relative;
  margin-bottom: 10px;
}
.dashboard_title a:last-child {
  margin-right: 0px;
}
.dashboard_title a.active,
.dashboard_title a:hover {
  color: #fff;
}
.dashboard_title a:after,
.dashboard_title a.active:after {
  content: "";
  width: 0%;
  position: absolute;
  height: 1px;
  bottom: -4px;
  background: var(--yellow-color);
  left: 0;
  transition: all 0.4s;
}
.dashboard_title a:hover:after,
.dashboard_title a.active:after {
  width: 100%;
}
.dashboard_title a i {
  font-size: 26px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}
.dashboard_title_btn {
  width: 270px;
  text-align: right;
}
.dashboard_title_btn .btn {
  line-height: 36px;
  margin-right: 8px;
  border: 1px solid var(--yellow-color);
  border-radius: 5px;
  color: var(--yellow-color);
}
.dashboard_title_btn .btn:last-child {
  margin-right: 0;
}
.dashboard_title_btn .btn.add_new_btn,
.dashboard_title_btn .btn.porduct_cancel,
.dashboard_title_btn .btn.Product_save {
  max-width: 120px;
  width: 100%;
}
.dashboard_title_btn .btn.Product_save {
  background: var(--yellow-color);
  color: #111;
}
.dashboard_title_btn .btn.porduct_cancel {
  background: #999;
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
}
.dashboard_title_btn .btn.icon_btn {
  padding: 0 10px;
  font-size: 20px;
}
.export_icon img {
  width: 38px;
}
.dashboard_heading .dashboard_filter {
  margin-left: 30px;
  display: flex;
}
.dashboard_heading .form-control {
  background: transparent;
  border-color: #555;
  color: var(--white-color);
  margin-left: 10px;
  font-size: 14px;
}
.dashboard_heading .form-control option {
  color: var(--blue-color);
}
.table_common {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}
.table_common .table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #444;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  border-top: none;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.table_common .table tbody td {
  color: #fff;
  font-size: 14px;
  vertical-align: middle;
  border: none;
  text-align: center;
}
.table_common .table tbody td:last-child {
  white-space: nowrap;
}
.secure-escort-listing .table_common .table tbody#getData td:last-child {
  white-space: unset;
}
.table_common .table thead th.left_align,
.table_common .table tbody td.left_align {
  text-align: left;
}
.table_common .table tbody td img {
  width: 40px;
  border-radius: 4px;
}
.table_common .switch_custom input:checked + .slider {
  background-color: #4c4538;
}
.table_common .switch_custom .slider:before {
  background-color: var(--yellow-color);
}
.table_common .table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--blue-color);
}
.table_common .table-striped tbody tr:nth-of-type(even) {
  background-color: #2e2844;
}
.testimonial-table .btn {
  padding: 0 12px;
}
.testimonial-table .btn-edit {
  background-color: #e03a6a;
}
.testimonial-table .circle-center {
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  top: -2px;
  margin: 0 3px;
}
.table_common.testimonial-table .table tbody td img {
  width: 80px;
}
.table_common .btn {
  border-radius: 3px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
.table_common .publish_btn {
  background: #5ac998;
}
.table_common .product_edit_btn {
  border: 1px solid #555;
  width: 110px;
}
.table_common .product_delete_btn {
  background: #4c4538;
  padding: 0 12px;
  font-size: 16px;
}
.table_common .order_reject_btn {
  background: #4c4538;
}
.table_common .order_accecpt_btn {
  background: var(--yellow-color);
}
.table_common .custom_radio [type="radio"]:checked + label:before,
.table_common .custom_radio [type="checkbox"]:checked + label:before,
.table_common .custom_radio [type="radio"]:not(:checked) + label:before,
.table_common .custom_radio [type="checkbox"]:not(:checked) + label:before {
  top: -7px;
}
.table_common .custom_radio [type="checkbox"]:checked + label:after,
.table_common .custom_radio [type="checkbox"]:not(:checked) + label:after {
  top: -7px;
}
/* Dashboard Table Design Start */
.secure-discussion-listing {
  margin-inline: 15px;
}
.table_common.secure-discussion-listing-table {
  margin-bottom: 0;
}
.secure-discussion-listing ul.pagination {
  justify-content: flex-end;
  padding-bottom: 25px;
  padding-right: 10px;
}
.secure-discussion-listing .secure-discussion {
  padding-bottom: 0;
}
.checkbox-width {
  width: 30px;
  max-width: 30px;
  min-width: 30px;
}
.report-client-title a {
  margin-bottom: 0;
}
.m-l-advance-search.escort-report-search {
  margin-bottom: 20px;
}
.escort-report-search .user_search {
  max-width: 100%;
}
.checkbox-width .form-control {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.checkbox-width .form-control:hover,
.checkbox-width .form-control:active,
.checkbox-width .form-control:focus {
  outline: 0 none;
  box-shadow: unset;
}
.secure-discussion-listing-table .title-disc {
  color: var(--light-color);
  font-weight: 600;
  font-size: 14px;
}
.dashboard_content .button_tab_outer {
  flex-flow: nowrap;
  overflow-x: auto;
}
.dashboard_content .button_tab_outer .btn.tab_btn {
  word-break: keep-all;
  white-space: nowrap;
}
.roomlet-listing.dashboard_content .table .button_tab_outer {
  margin-top: 10px;
}
.roomlet-listing.dashboard_content .table .button_tab_outer .btn-yellow {
  color: #000 !important;
}
.discussion_dash .secure-discussion-listing-table .discussion_post_top {
  color: #fff;
}
.discussion_dash .secure-discussion-listing-table .discussion_post_top strong {
  display: inline-block;
  margin-left: 10px;
  min-width: 80px;
}
.discussion_dash .secure-discussion-listing-table .discussion_post_top > a {
  min-width: 40px;
}
.table_common.secure-discussion-listing-table .table tbody td img {
  width: 40px;
  border-radius: 50%;
  height: 40px;
}
.discussion-btn-wrapper {
  display: flex;
}
.delete-multiple {
  margin-right: 15px;
}
.dashboard_title.user_single_anchor {
  width: 100%;
}
.secure-escort-listing .row.filter-listing-row {
  margin-inline: 0;
}
.secure-escort-listing .table_common {
  margin-inline: 15px;
  margin-top: 20px;
}
.dashboard_content .form-control:hover,
.dashboard_content .form-control:active,
.dashboard_content .form-control:focus {
  outline: 0 none;
  box-shadow: unset;
}
.secure-escort-listing .table_common.image-listing-table {
  margin-inline: 0px;
  margin-top: 20px;
}
.secure-escort-listing .table_common .blue_outline {
  margin-top: 15px;
}
.secure-escort-listing .dashboard_title {
  width: 100%;
}
.secure-escort-listing .user_search .form-control {
  background: var(--blue-color);
  border: none;
  padding-right: 35px;
}
.secure-escort-listing .card-body {
  overflow-x: auto;
}
.secure-escort-listing .card-body .row {
  flex-wrap: nowrap;
}
.secure-escort-listing .card-body .row:first-child .col-3 {
  min-width: 260px;
}
.secure-escort-listing .card-body .row:nth-child(2) .col-3 {
  min-width: 190px;
}
.secure-escort-listing .form-control:hover,
.secure-escort-listing .form-control:active,
.secure-escort-listing .form-control:focus {
  outline: 0 none;
}
/* Dashboard Table Design End */
/* Blogs Design CSS Start */
.dashboard_heading.title-wrap {
  flex-wrap: nowrap;
}
.admin-blog {
  display: flex;
  /* align-items: center; */
  max-width: calc(100% - 90px);
}
.blog-inner {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  position: relative;
}
.blog-content {
  padding-left: 25px;
}
.blog-image {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  max-width: 145px;
  width: 100%;
  height: 120px;
}
.blog-create-form label {
  text-align: left;
}
.blog-posted {
  color: #e03a6a;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 8px 0;
}
.blog-title {
  font-size: 18px;
  color: #000;
  max-width: 307px;
}
.blog-text {
  color: #515050;
  font-size: 16px;
  margin: 8px 0px;
}
.blog-text p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-text .read-more {
  color: #e03a6a;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin-top: 8px;
}
.blogs-section .dashboard_heading.title-wrap {
  margin-bottom: 0;
}
.single-blog {
  margin-top: 20px;
}
.edit-blogs {
  position: absolute;
  right: 10px;
  top: 10px;
}
.blogs-section .btn {
  border-radius: 3px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 12px;
}
.blogs-section .btn-edit {
  background-color: #e03a6a;
}
.testimonial-modal {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}
.testimonial-modal .modal-dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%) !important;
  max-width: 400px;
}
.review-image-add {
  text-align: center;
}
.review-image-add img {
  height: 80px;
  display: inline-block;
  border-radius: 50%;
  width: 80px;
  margin-bottom: 15px;
}
.testimonial-modal .modal-header {
  background-color: #f7f7f7;
  justify-content: center;
}
.testimonial-modal .modal-header .modal-title {
  font-size: 20px;
  color: #000;
  text-align: center;
  font-weight: bold;
}
.testimonial-modal .modal-header button.close {
  margin: 0;
  padding: 0;
  position: absolute;
  right: -8px;
  top: -8px;
  opacity: 1;
  color: #e03a6a;
  text-shadow: unset;
  font-size: 22px;
  background-color: #fff;
  width: 20px;
  border-radius: 50%;
  box-shadow: 1px 1px 6px #ccc;
  height: 20px;
  line-height: 20px;
}
.review-content textarea {
  background-color: #f7f7f7;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  resize: none;
  min-height: 150px;
  padding: 10px 15px;
}
.testimonial-write.blog-add .form-control {
  margin-bottom: 20px;
  background-color: #f7f7f7;
}
.review-content textarea:focus {
  outline: 0 none;
  box-shadow: unset;
}
.testimonial-modal .submit-review {
  color: #fff;
  background: #e03a6a;
  font-weight: normal;
  text-transform: uppercase;
  padding: 2px 20px;
  line-height: 30px;
  max-width: 160px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: 50px;
}
.testimonial-modal .submit-review:hover {
  background: #c22e59;
}
.form-group.file-group .form-control {
  position: absolute;
  width: 100%;
  max-width: 145px;
  opacity: 0;
  height: 100%;
  margin: 0;
  cursor: pointer;
}
.form-group.file-group {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.review-blog {
  display: flex;
  margin-bottom: 1.25rem;
  align-items: center;
}
.review-image {
  height: 72px;
  width: 72px;
  border-radius: 50%;
}
.blog-review-user {
  padding-left: 15px;
}
.blog-review-user h5 {
  color: #000;
  font-weight: bold;
  display: block;
}
.blog-review-user p {
  color: #000;
  opacity: 0.6;
}
.review-content .form-group label {
  color: #000;
  font-weight: bold;
}
.rate-now .fa {
  color: #ccc;
  font-size: 24px;
  cursor: pointer;
}
.rate-now .fa.checked {
  color: #f6f60b;
}
/* Blogs Design CSS End */
.grid-item.row.escorts-row {
  justify-content: space-between;
}
.escorts-row .gallery-item.qw {
  width: 100%;
  max-width: calc(25% - 15px);
  margin-bottom: 20px;
}
.secure-escort-profiles .about_rate12 .col-md-4 {
  padding: 0;
}
.secure-escort-profiles .about_rate12 h3 {
  font-size: 22px;
}
.secure-escort-profiles .about_rate12 h3 + h3 {
  margin-top: 15px;
}
.secure-escort-profiles .about_rate.about_rate12 {
  margin-top: 14px;
}
.secure-escort-profiles .gallery-item.qw {
  position: relative;
}
.secure-escort-profiles .gallery-item.qw .img-responsive {
  max-height: 420px;
}
.secure-escort-profiles .gallery-item.qw .deleteImage {
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: #d9534f;
  font-weight: 600;
  padding: 0 16px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 50px;
  display: inline-block;
  color: #fff;
  transition: all 0.4s;
}
.table_common .pagination {
  justify-content: center;
  padding-bottom: 15px;
}
.secure-escort-profiles .gallery-item.qw .deleteImage:hover {
  background-color: #d42b26;
}
/*----- User Dashboard -----*/
.table_common .pink_outline {
  border: 1px solid var(--pink-color);
  width: 110px;
  color: var(--pink-color) !important;
  line-height: 34px;
}
.button_tab_wrapper {
  flex-flow: wrap;
}
.button_tab_outer {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}
.button_tab_outer li a.tab_btn {
  font-size: 16px;
  background: var(--blue-color);
  color: var(--light-color);
  border-radius: 7px;
  margin: 0px 16px 16px 0px;
}
.button_tab_outer li a.tab_btn:lasr-child {
  margin-right: 0px;
}
.button_tab_outer li a.tab_btn.active,
.button_tab_outer li a.tab_btn:hover {
  background: var(--yellow-color);
  color: var(--blue-color);
}
.user_search {
  position: relative;
  margin-bottom: 16px;
  max-width: 350px;
  width: 100%;
}
.user_search .user_seacrh_icon {
  position: absolute;
  right: 16px;
  top: 11px;
}
.user_search .form-control {
  background: var(--blue-color);
  border: none;
}
.table_common.user-dashboard-table .table tbody td a,
.table_common.media_page .table tbody td a {
  color: #fff;
}
.table_common .table tbody td:last-child:not(.left_align) .btn + .btn {
  margin-left: 8px;
  white-space: nowrap;
}
.offline-payment-modal .modal-dialog .close {
  color: #dc3545;
  opacity: 1;
  position: absolute;
  right: -15px;
  padding: 0;
  margin: 0;
  z-index: 1;
  top: -15px;
}
.offline-payment-modal .modal-dialog .modal-body {
  padding: 0;
}
.offline-payment-modal .modal-header {
  padding: 0;
  position: relative;
}
.offline-payment-modal .modal-title {
  margin: 0;
}
/*----- User Setting Page -----*/
.setting_btn_outer {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  width: 100%;
}
.setting_btn_outer li {
  width: 20%;
  margin-bottom: 16px;
  padding: 0 5px;
}
.setting_btn_outer .btn {
  background: var(--blue-color);
  color: #fff;
  width: 100%;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  line-height: 70px;
  box-shadow: inset 0 -4px 0px 0px #3f375d;
  font-size: 18px;
  font-weight: 400;
}
.dashboard_title.user_single_anchor a:after,
.dashboard_title.user_single_anchor a.active:after {
  display: none;
}

/*----- Media -----*/
.media_page .file {
  display: flex;
  align-items: center;
}
.media_page .table tbody td .file img {
  width: 60px;
}
.media_page .file span {
  margin-left: 10px;
}
.media_page table small {
  color: var(--light-color);
}
.table_common .edit_btn {
  width: auto;
  color: var(--yellow-color) !important;
}
.table_common .blue_outline {
  border: 1px solid #17a2b8;
  color: #17a2b8 !important;
  line-height: 34px;
}
.table_common .blue_outline.btn-red {
  color: #fff !important;
}
.user_dashboard_title_btn .btn {
  border: 1px solid #302b44;
  color: var(--yellow-color);
  background: #302b44;
}
.button_tab_outer .custom_select_outer {
  position: relative;
  width: 180px;
  margin-bottom: 16px;
  margin-right: 10px;
}
.button_tab_outer .custom_select_outer .form-control {
  background: var(--blue-color);
  border-color: var(--blue-color);
  color: #fff;
}
.custom_select_outer svg {
  position: absolute;
  top: 17px;
  right: 9px;
}
.user_header_dashboard .dashboard_title {
  display: flex;
  align-items: center;
  width: auto;
}
.user_header_dashboard .dashboard_title_btn {
  width: auto;
  text-align: right;
}
.user_header_dashboard .dashboard_title_btn .btn.add_new_btn {
  max-width: inherit;
  width: auto;
}

/*----- Chat Box -----*/
.chatbox_outer {
  background: #fff;
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  height: calc(100vh - 180px);
}
.chat_contact {
  background: #fff;
  width: 300px;
  float: left;
  border-radius: 8px 0 0 8px;
  height: 100%;
}
.dashboard_content div#getChatboxList {
  height: calc(100% - 74px);
  overflow-y: auto;
}
.dashboard_content .chating_outer {
  height: calc(100% - 150px);
  overflow-y: auto;
}
.current_chatbox {
  width: calc(100% - 300px);
  float: left;
  background-color: #fdf0f3;
  border-radius: 0 8px 8px 0;
  height: 100%;
}
.chat_contact .user_search {
  margin: 0;
  padding: 14px;
}
.current_chatheadding h1,
.current_chatheadding h1 a {
  color: #000;
}
.chat_contact .user_search .form-control {
  background: #fff;
  height: 46px;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 5px;
}
.chat_contact .user_search .user_seacrh_icon {
  position: absolute;
  right: 32px;
  top: 30px;
  color: #e03a6a;
  font-size: 16px;
}
.chatuser-list {
  display: inline-block;
  width: 100%;
  padding: 12px 14px;
  cursor: pointer;
  position: relative;
}
.chatuser-list.active {
  background: #efefef;
}
.chatuser_img {
  width: 32px;
  float: left;
  margin-right: 10px;
}
.chatuser_img img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.chatuser_name {
  width: calc(100% - 42px);
  float: left;
}
.chatuser_name h6 {
  font-size: 15px;
  margin-bottom: 1px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.chatuser_name h6 b {
  font-size: 14.5px;
  font-weight: 500;
  color: #333;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
span.newMsg {
  position: absolute;
  background: #e03a6a;
  right: 16px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  border-radius: 54px;
  padding: 1px 0;
}
.chatuser_name h6 .chat_time {
  font-size: 11px;
  font-weight: 300px;
  color: var(--light-color);
}
.chatuser_name p {
  font-size: 12px;
  color: var(--light-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
}
.current_chatheadding {
  border-bottom: 2px solid #ccc;
  padding: 14px 20px;
}
.playerIcons #sendMessagess {
  position: relative;
  justify-content: space-between;
  padding: 0 15px;
}
.playerIcons #sendMessagess .col-2 {
  padding: 0;
  max-width: 75px;
}
.playerIcons #sendMessagess .col-10 {
  max-width: calc(100% - 105px);
  flex: 0 0 100%;
}
.playerIcons #sendMessagess .col-2 .row {
  margin: 0;
}
.my_chts.dashboard_content .btn.btn_pink.btn-block {
  line-height: 50px;
}
.playerIcons #sendMessagess .btn-send {
  height: 48px;
  width: 75px;
  display: inline-block;
  font-size: 16px;
}
.playerIcons #sendMessagess .upload_files {
  padding: 0;
  right: 30px;
  z-index: 1;
  position: absolute;
  top: 2px;
}
.playerIcons #sendMessagess .emojionearea.emojionearea-inline {
  border-radius: 28px;
  height: 50px;
  box-shadow: 0 0 6px #ccc;
  padding-right: 70px;
  padding-left: 10px;
  min-width: none;
}
.playerIcons
  #sendMessagess
  .emojionearea.emojionearea-inline
  > .emojionearea-editor {
  top: 14px;
  right: 15px;
}
.main_content.dashboard_content + .footer {
  display: none;
}
.playerIcons
  #sendMessagess
  .emojionearea.emojionearea-inline
  > .emojionearea-button {
  top: 12px;
}
.current_chatheadding h1 {
  font-size: 20px;
  margin-bottom: 4px;
}
.current_chatheadding h6 {
  font-size: 13px;
  color: #333;
  display: flex;
  align-items: center;
}
.current_chatheadding h6 img {
  width: 18px;
  margin-right: 7px;
}
.chating_outer {
  padding: 20px;
}
.chat_in {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.chat_in .chat_in_img {
  width: 40px;
  float: left;
  margin-right: 10px;
  position: absolute;
  bottom: 20px;
}
.chat_in .chat_in_img img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  object-fit: cover;
  border: 1px solid #ddd;
}
.chat_in .chat_in_text {
  width: calc(100% - 32px);
  float: left;
  background: #2a253f;
  border-radius: 12px 12px 12px 0px;
  padding: 12px;
  max-width: 360px;
  margin-left: 32px;
}
.chat_in .chat_in_text p {
  font-size: 13px;
  color: #fff;
}
.chat_in .chat_in_text small {
  text-align: right;
  width: 100%;
  display: inline-block;
  font-size: 11px;
  color: var(--light-color);
}
.chat_out {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.chat_out .chat_in_text {
  width: calc(100% - 32px);
  background: var(--yellow-color);
  border-radius: 12px 12px 12px 0px;
  padding: 12px;
  max-width: 360px;
  margin-left: 32px;
  float: right;
}
.chat_out .chat_in_text p {
  font-size: 13px;
  color: #000;
}
.chat_out .chat_in_text small {
  text-align: right;
  width: 100%;
  display: inline-block;
  font-size: 11px;
  color: #000;
}

.view_profile_link {
  background: #252037;
  padding: 24px;
  border-radius: 8px;
}
.view_profile_linkimg img {
  width: 120px;
  border-radius: 6px;
}
.view_profile_name {
  padding: 14px 20px;
  text-align: center;
}
.view_profile_name h1 {
  font-size: 20px;
  margin-bottom: 4px;
}
.view_profile_name h6 {
  font-size: 13px;
  color: var(--yellow-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.view_profile_name h6 img {
  width: 18px;
  margin-right: 7px;
}
.chat_viewprofile_btn {
  border: 1px solid var(--yellow-color);
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 10px;
  color: #fff;
  border-radius: 6px;
  background: #302b44;
  margin-top: 20px;
}

/*----- Admin From -----*/
.admin_fonm_outer {
  background: var(--blue-color);
  border-radius: 8px;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  padding: 40px 20px;
}
.admin_fonm_outer .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.admin_fonm_outer label {
  width: 225px;
  float: left;
  text-transform: uppercase;
  color: var(--white-color);
  margin-bottom: 0;
  font-size: 14px;
  min-width: 225px;
}
.admin_fonm_outer .form-control {
  width: calc(100% - 250px);
  float: left;
  border-radius: 20px;
  border: none;
  background-color: #332d4a;
  font-size: 14px;
  height: 42px;
  padding: 7px 16px;
}
.admin_fonm_outer .form-control {
  width: calc(100% - 250px);
  float: left;
  border-radius: 20px;
  border: none;
  background-color: #fff;
  font-size: 14px;
  height: 42px;
  padding: 7px 16px;
}
.reason-form .reason-height {
  min-height: 90px;
  word-break: break-word;
  height: auto;
}
.form-group.sim input {
  width: calc(100% - 311px);
}
.user_form .admin_fonm_outer .form-control {
  color: #fff;
}
.table_common .table tbody td.report_des {
  color: #fff;
  font-size: 14px;
  vertical-align: middle;
  border: none;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  max-width: 200px;
  cursor: pointer;
}
.admin_commnet1_left {
  width: calc(100% - 430px);
}
.admin_commnet {
  display: flex;
  flex-wrap: wrap;
}
.admin_commnet1_right {
  background: #fff;
  width: 35%;
  padding: 0 0 80px;
  position: relative;
}
.admin_commnet1_right_top {
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
}
.admin_commnet1_right_top1 {
  display: flex;
}
.admin_commnet1_left {
  width: 65%;
}
.admin_commnet1_right1 {
  width: 34px;
  height: 34px;
  margin: 0 8px 0 0;
}
.admin_commnet1_right_top1 h1 {
  font-size: 14px;
  margin: 0;
  color: #333;
  width: 100%;
}
.dropdown.rig button:after {
  display: none;
}
.dropdown.rig button.btn.btn-primary.dropdown-toggle span {
  background: #ddd;
  height: 7px;
  width: 7px;
  float: left;
}
.dropdown.rig button.btn.btn-primary.dropdown-toggle span {
  background: #ddd;
  height: 5px;
  width: 5px;
  float: left;
  margin: 0 1px;
  border-radius: 24px;
}
.dropdown.rig .dropdown-menu.show {
  transform: translate(0px, 0px) !important;
  left: auto !important;
  right: 0 !important;
  top: 13px !important;
}

.admin_commnet1_right_top1 {
  display: flex;
  align-items: center;
  margin: 0 0 7px;
}
.admin_commnet1_right_t1 {
  overflow: auto;
  max-height: 100vh;
}
.admin_commnet1_right_t1::-webkit-scrollbar {
  display: none;
}
.bottom_cht {
  padding: 0px 20px;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
}
.bottom_cht textarea {
  width: calc(100% - 55px);
  box-shadow: 0 0 10px #eee;
  border-radius: 500px;
  border: none;
  resize: none;
  outline: none;
  font-size: 14px;
  padding: 12px 20px;
  height: 48px;
}
.button_tab_outer li + li {
  margin-left: 15px;
}

.button_tab_outer li {
  margin-top: 8px;
}
.video-listing-dashboard .button_tab_outer li {
  margin-top: 0px;
}
.ads-modal .modal-content {
  background: #131419 !important;
}
.ads-modal .modal-header {
  background-color: #131419 !important;
  display: flex;
  justify-content: flex-end;
}
.ads-modal .modal-content p {
  margin-top: 12px;
}
a.pls11 {
  position: absolute;
  right: 116px;
  font-size: 22px;
  top: 8px;
  color: #999;
}
a.pls12 {
  position: absolute;
  right: 87px;
  font-size: 22px;
  top: 8px;
  color: #999;
}
.bottom_cht button {
  background: #e0565f;
  color: #fff;
  border-radius: 260px;
  width: 47px;
  border: none;
  height: 47px;
  position: relative;
  top: -19px;
  font-size: 21px;
  left: 4px;
  outline: none;
}
.admin_commnet1_left > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.admin_commnet1_right_top p {
  font-size: 12px;
  color: #333;
}
.admin_commnet1_right_top12 .lik22 {
  margin: 8px 0 0 5px;
  color: #666;
  font-weight: 600;
  font-size: 14px;
}
.admin_commnet1_right_t {
  padding: 0 20px;
}
.admin_commnet1_right_top12 a {
  color: #666 !important;
}
.admin_commnet1_right_top12 a img {
  width: 18px;
  display: inline-block;
  margin: -3px 0 0;
}
.admin_commnet1_right_top12 a.ds img {
  filter: grayscale(1);
  opacity: 0.6;
}
.admin_commnet1_right_top12 a.ds1 img {
  filter: grayscale(1);
  opacity: 1;
}
.admin_commnet1_right_top12 a.ds:hover img {
  filter: inherit;
  opacity: 1;
}
.admin_commnet1_right_top12 a.ds1:hover img {
  filter: inherit;
}
.admin_commnet1_right_top12 {
  display: flex;
}
.admin_commnet1_right_top12 .lik22 i {
  color: #999;
  font-size: 17px;
}
.repl {
  margin-left: auto;
  margin-top: 11px;
}
.repl a {
  color: #fd5252 !important;
  font-size: 14px;
  font-weight: 600;
}
.dropdown.rig button.btn.btn-primary.dropdown-toggle {
  padding: 0;
  line-height: 0;
  color: #333;
  background: #fff;
  border: none;
}
.dropdown.rig {
  float: right;
}
.admin_commnet1_right1 img {
  height: 100%;
  border-radius: 500px;
}
.exampleModalRejectOptio {
  background: rgba(0, 0, 0, 0.7);
}
.exampleModalRejectOptio button.close {
  color: #fff;
  box-shadow: none !important;
  font-weight: 100;
  font-size: 33px;
  text-shadow: none;
}
.exampleModalRejectOptio button.tab_btn {
  border: 1px solid #17a2b8;
  background: no-repeat;
  width: 100%;
  padding: 9px 0;
  color: #17a2b8;
}
.exampleModalRejectOptio .close:hover {
  color: #fff;
  text-decoration: none;
  opacity: 1;
}
.exampleModalRejectOptio select {
  border-radius: 0 !important;
}
.exampleModalRejectOptio .modal-header {
  border-bottom: 1px solid #353742;
}
.exampleModalRejectOptio .modal-dialog {
  border: 1px solid #353742;
}
.discussion_dash {
  padding: 20px 30px;
}
.discussion_dash .discussion_post > h5 {
  margin: 7px 0 16px;
}
.discussion_dash .discussion_post > h5 > a {
  color: #222;
  font-size: 20px;
  font-weight: bold;
}
.discussion_dash .post_images {
  border: 1px solid #f5f5f5;
  height: 330px;
  overflow: hidden;
  margin: 0 0 14px;
  border-radius: 4px;
}
.discussion_dash .post_images > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}
.discussion_dash .discussion_post_top {
  display: flex;
  color: #333;
  align-items: center;
}
.discussion_dash .discussion_post_top > a {
  display: flex;
  color: #666;
  margin: 0;
  align-items: center;
}
.discussion_dash .discussion_post_top > a strong {
  font-size: 14px;
  margin: 0 9px;
  text-transform: capitalize;
}
.discussion_dash .img00 {
  width: 30px;
}
.discussion_dash .discussion_post_top font {
  font-size: 11px;
  font-weight: 500;
  color: #aaa;
}
.discussion_dash .wach {
  margin: 0 0 0 26px;
}
.discussion_dash .discussion_post_top font {
  font-size: 11px;
  font-weight: 500;
  color: #aaa;
}
.discussion_dash .discussion_post {
  background: #fff;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.discussion_dash .discussion_post p {
  font-size: 14px;
  margin: 10px 0;
  color: #888;
}
.discussion_dash button.mrs {
  border: none;
  background: no-repeat;
  padding: 0;
  outline: none;
}
.discussion_dash .mrs {
  color: #e03a6a;
  font-size: 13px;
  margin: 0 0 0 5px;
}
.discussion_dash .toggle-desc .desc-more {
  display: none;
}
.discussion_dash .img00 img {
  width: 100%;
  height: 30px;
  border-radius: 100px;
}
.coms_like.yy.discussion_detils_6.tk {
  color: #333;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  justify-content: flex-start;
  padding-bottom: 13px;
}
.coms_like.yy.discussion_detils_6.bt_btn {
  justify-content: flex-end;
  display: flex;
  margin: -43px 0 0;
}
.coms_like.yy.discussion_detils_6.bt_btn a {
  margin: 0 4px;
}
.discussion_detils_6.tk .btn {
  padding: 0 10px;
}
.discussion_detils_6.coms_like.yy.tk img {
  width: 18px;
  margin: -2px 0 0;
  height: auto !important;
  transition: all ease 0.5s;
}
.coms_like.yy.tk a {
  color: #333;
}
.lik22.tk {
  margin: 0 0 0 20px;
  font-size: 15px;
}
.dropdown.Edit32 button.btn.btn-primary.dropdown-toggle:after {
  display: none;
}
.dropdown.Edit32 {
  position: absolute;
  top: 14px;
  right: 20px;
}
.dropdown.Edit32 .dropdown-menu {
  position: absolute;
  transform: none !important;
  top: 30px !important;
  left: auto !important;
  will-change: transform;
  right: 0;
}
.dropdown.Edit32 button.btn.btn-primary.dropdown-toggle {
  background: no-repeat;
  border: none;
  color: #999;
  font-size: 25px;
  outline: none;
  box-shadow: none;
}

.discussion_lt1 {
  display: flex;
  position: relative;
}
.discussion_lt1_img {
  flex: 0 0 40px;
  height: 40px;
}
.discussion_lt1_img img {
  height: 100%;
  border-radius: 45px;
}
.discussion_lt1cnt {
  padding: 0 9px;
  position: relative;
  width: 100%;
}
.discussion_lt1cnt_top {
  display: flex;
  color: #111;
  height: 40px;
  align-items: center;
}
.discussion_lt1cnt_top h3 {
  font-size: 16px;
}
.discussion_lt1 .discussion_lt1cnt_top span {
  font-size: 11px;
  margin: 0 0 0 10px;
}
.discussion_lt1 .discussion_lt1cnt > p {
  font-size: 13px;
  color: #333;
}
.discussion_lt1 .coms_like {
  color: #333;
  display: flex;
  align-items: center;
}
.discussion_lt1 .coms_like.yy a {
  color: #333;
}
.discussion_dash.discussion_dash_details .discussion_detils_6.coms_like.yy img {
  width: 18px;
  margin: -2px 0 0;
  height: auto !important;
  transition: all ease 0.5s;
}
.discussion_dash.discussion_dash_details .posted_by_text a {
  color: #333 !important;
}
.discussion_dash.discussion_dash_details .coms_like.yy button {
  line-height: 16px;
  color: #999 !important;
  font-weight: normal;
}
.discussion_dash.discussion_dash_details h6.cnt_reply {
  font-size: 12px;
  margin: 0;
  padding: 0 0 0 50px;
  position: relative;
}
.discussion_dash.discussion_dash_details .rly {
  position: relative;
  padding: 0 0 0px;
  display: none;
  width: 100%;
}
body .discussion_dash.discussion_dash_details .discussio_chat .leave_comment {
  margin: 0px 0px 0px;
  position: relative;
}
.discussion_dash.discussion_dash_details
  .discussion_lt1cnt
  .leave_comment
  textarea.form-control {
  color: #333 !important;
}
body .discussion_dash.discussion_dash_details .leave_comment .btn {
  position: initial;
  margin: 6px 0 0;
  right: 7px;
  float: right;
  color: #333 !important;
}
.discussion_dash.discussion_dash_details
  .discussion_lt1cnt
  .leave_comment
  button.btn.btn_pink {
  height: 30px;
  line-height: 0;
  font-size: 14px;
  background: #eee;
}
.discussion_dash.discussion_dash_details .discussions_replied {
  padding: 7px 0 9px;
}
.discussion_dash.discussion_dash_details
  .discussions_replied.mb-4
  h6.text-dark {
  color: #999 !important;
  font-weight: 800;
  font-size: 13px;
  margin-top: 9px;
  margin-left: -50px;
}
.discussion_dash.discussion_dash_details
  .discussio_chattop2
  .discussions_replied {
  padding: 7px 0 9px;
}
.discussion_dash.discussion_dash_details
  .discussions_replied.discussions_replied1.mb-4 {
  padding-left: 50px;
}
.discussion_dash.discussion_dash_details .posted_by_text a {
  color: #333 !important;
}
.discussion_dash.discussion_dash_details .like_comment.rpll font {
  color: #000;
}
.discussion_dash.discussion_dash_details
  .discussions_replied.discussions_replied1.mb-4
  .like_comment
  a {
  color: #999;
  margin: 0 5px 0 10px;
}
.discussion_dash.discussion_dash_details
  .discussions_replied.discussions_replied1.mb-4
  .like_comment
  a {
  color: #999;
  margin: 0 5px 0 10px;
}
.discussion_dash.discussion_dash_details .dropdown.rit_3_dots {
  position: absolute;
  top: 0;
  right: 0;
}
.discussion_dash.discussion_dash_details .dropdown.rit_3_dots button {
  background: no-repeat !important;
  border: none;
  color: #999;
  font-size: 0;
  padding: 0;
  line-height: 0;
  outline: none !important;
}
.discussion_lt1cnt_top h3 {
  font-size: 16px;
  color: #333;
}
.discussion_dash.discussion_dash_details .like_comment.rpll button {
  color: #333;
}
.ctegor > input {
  width: 100% !important;
  margin: 0 0 10px;
}
.ctegor img {
  width: 90px;
}

.ctegor {
  width: calc(100% - 246px);
}
button.expend {
  display: none;
}
@media (max-width: 1366px) {
  .shop_custom_tab .nav-tabs .nav-link {
    width: 175px;
  }
  .setting_btn_outer .btn {
    font-size: 16px;
  }
}

@media (max-width: 1199px) {
  .card_five {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .shop_custom_tab .nav-tabs .nav-link {
    width: 142px;
  }
  .about_details li {
    width: 33.33%;
  }
  .setting_btn_outer li {
    width: 25%;
  }
  .secure-escort-listing .escortDetails .col-2 {
    min-width: 160px;
    margin-bottom: 10px;
  }
  .secure-escort-listing .escortDetails .col-10 {
    min-width: calc(100% - 160px);
  }
}
@media (max-width: 992px) {
  button.expend {
    display: block;
    background: none;
    border: none;
    font-size: 15px;
    margin: 0;
    float: right;
    position: relative;
    z-index: 999;
    padding: 3px 5px;
    background: #e03a6a;
    margin-bottom: 10px;
    color: #fff;
    margin-right: 13px;
    margin-top: 5px;
  }
  button.expend:hover,
  button.expend:active,
  button.expend:focus {
    outline: 0 none;
    box-shadow: unset;
  }
  .my_chts .chat_contact {
    transition: all ease 0.5s;
    position: absolute;
    z-index: 99990;
    height: 98%;
    top: 0;
    margin: 0;
    width: 50px !important;
    overflow: hidden;
    padding-bottom: 30px;
  }
  .my_chts.expend1 .chat_contact {
    width: 230px !important;
  }
  .expend1 button.expend {
    transform: rotate(180deg);
    /* margin-right: 0; */
  }
  .my_chts .chatbox_outer {
    overflow: hidden;
  }
  .my_chts span.newMsg {
    top: 13px;
  }
  .current_chatbox {
    width: 100%;
    padding-left: 50px;
  }
  .chatuser-list {
    padding: 8px 10px;
  }
  .my_chts .user_search .user_seacrh_icon {
    top: 57px;
    right: 18px;
  }
  .my_chts .btn.btn-block {
    line-height: 46px;
  }
  .playerIcons #sendMessagess .col-10 {
    max-width: calc(100% - 84px);
    padding-left: 0;
  }
  .playerIcons #sendMessagess .emojionearea.emojionearea-inline {
    padding-right: 40px;
    min-width: 100% !important;
  }
  .playerIcons #sendMessagess .upload_files {
    right: 37px;
    top: 0;
  }
}
@media (max-width: 991px) {
  .card_five,
  .card_four {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .codedruns_outer .right_content .top_header .top_search {
    margin-left: 20px;
    max-width: 200px;
  }
  .top_button {
    width: calc(100% - 380px);
  }
  .main_heading .heading_title,
  .product_right_detail h2,
  .weight_height .weight_height_box h5 {
    font-size: 20px;
  }
  .main_heading .bnt_right .form-control {
    margin-right: 7px;
  }
  .card_bottom_text h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .edit_pick_details .card_detail .card_img {
    height: 330px;
  }
  .edit_pick_details .card_detail .card_img img {
    height: 330px;
  }
  .codedruns_forum h4 {
    font-size: 16px;
    line-height: 25px;
  }
  .profile_menu li {
    padding: 14px 20px;
    float: left;
    width: 33.33%;
  }
  .edit_profile_form {
    margin-top: 24px;
  }
  .profile_complete {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-top: 11px;
  }
  .setting_btn_outer li {
    width: 33.33%;
  }
  .admin_commnet1_left {
    width: 100%;
  }
  .admin_commnet1_right {
    background: #fff;
    width: 100%;
  }
  .admin_commnet1_right_t1 {
    overflow: auto;
    max-height: 440px;
  }
  .video-listing-dashboard .button_tab_outer {
    width: 100%;
  }
  .video-listing-dashboard .user_search {
    max-width: 100%;
  }
  .video-listing-dashboard .btn-primary {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .card_five,
  .card_four {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .codedruns_outer .right_content .top_header {
    padding: 10px 20px;
  }
  .main_content {
    margin-top: 72px;
    padding: 16px;
  }
  .top_button .btn {
    width: 75px;
    font-size: 13px;
    line-height: 30px;
    padding: 0 5px;
    margin: 0;
  }
  .desktop_view {
    display: none;
  }
  .mobile_view {
    display: block;
  }
  .top_button {
    width: calc(100% - 185px);
    display: block;
    text-align: right;
  }
  .codedruns_outer .right_content .top_header .top_search {
    margin-left: 0;
    max-width: 100%;
    margin-top: 10px;
  }
  .codedruns_outer .let_menu_bar {
    width: 0;
    float: left;
    overflow-x: hidden;
    left: 0;
    z-index: 9;
    transition: all 0.4s;
  }
  #dashboard_header.codedruns_outer .let_menu_bar {
    width: 0px;
  }
  .main_heading .heading_title {
    margin: 10px 0 8px;
  }
  .main_heading .bnt_right .btn {
    line-height: 25px;
    font-size: 12px;
    margin-right: 3px;
    padding: 0 10px;
    margin-top: 7px;
  }
  .main_heading .bnt_right .form-control {
    font-size: 12px;
    height: 28px;
    margin-top: 6px;
    float: left;
    padding: 0 8px;
  }
  .toggle_nav {
    width: 30px;
    float: left;
    margin: 7px 16px 0px 0px;
    display: inline-block;
  }
  .codedruns_outer .right_content,
  .codedruns_outer .right_content .top_header {
    width: 100%;
  }
  #dashboard_header.codedruns_outer .right_content {
    width: 100%;
  }
  #dashboard_header.codedruns_outer .right_content .top_header {
    width: 100%;
  }
  #dashboard_header .let_menu_bar ul li.first_menu.close_btn {
    text-align: right;
    margin-right: 16px;
  }
  #dashboard_header .dashboard_logo {
    display: none;
  }
  #dashboard_header .let_menu_bar ul li a img {
    margin-right: 32px;
  }
  .let_menu_bar ul li.first_menu {
    display: none;
  }
  .let_menu_bar ul li.first_menu.close_btn {
    display: block;
    margin-bottom: 0px;
  }
  .let_menu_bar ul li.first_menu.close_btn .fa-close {
    background: var(--light-pink);
    border: 1px solid #ddd;
    width: 32px;
    height: 32px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 16px;
    text-align: center;
  }
  .footer_socil_link {
    margin-left: 0;
    text-align: center;
  }
  .footer_socil_link a {
    margin: 0px 7px 5px;
    font-size: 18px;
    display: inline-block;
  }
  .main_heading .heading_title,
  .product_right_detail h2,
  .weight_height .weight_height_box h5 {
    font-size: 18px;
  }
  .product_right_detail h2 {
    margin-top: 20px;
  }
  .card_bottom_text h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .classified_detail h3,
  .classified_text h3 {
    font-size: 16px;
  }
  .classified_text h6,
  .classified_detail h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .right_heading .heading_title {
    margin-top: 20px;
  }
  .shop_custom_tab .nav-tabs .nav-link {
    padding: 12px 12px 16px;
    font-size: 16px;
    width: 104px;
  }
  #gallery .nav-tabs .nav-item .nav-link {
    font-size: 13px;
  }
  .porfile_outer .main_heading {
    margin-top: 36px;
  }
  .edit_profile_outer .card_img {
    margin-bottom: 20px;
  }
  .profile_menu_outer {
    padding-right: 0px;
    margin-bottom: 20px;
    display: inline-block;
  }
  .discussions_tab .nav-link {
    width: 85px;
    font-size: 14px;
  }
  ul.warning_list li {
    margin-bottom: 5px;
    font-size: 13px;
  }
  .common_popup#sign_up_escort form .form-group {
    display: block;
  }
  .common_popup#sign_up_escort form label {
    width: 100%;
    margin: 16px 0 10px;
  }
  .common_popup#sign_up_escort .form-control,
  .common_popup#sign_up_escort input {
    width: 100%;
  }
  .profile_menu li {
    width: 50%;
  }
  .porfile_outer {
    margin-top: 20px;
  }
  .edit_profile_outer .about_details li {
    width: 50%;
  }
  .video_bottom_icon a span {
    display: none;
  }
  .chat_contact,
  .current_chatbox {
    width: 100%;
  }
  .setting_btn_outer li {
    width: 50%;
  }
  .modal.offline-payment-modal {
    padding-right: 0 !important;
    max-width: 90%;
    margin: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    max-height: 90vh;
  }
  .offline-payment-modal .modal-dialog .close {
    right: -6px;
    top: -10px;
    font-size: 30px;
  }
  .admin_fonm_outer.ed55 .form-group {
    display: block;
  }
  .admin_fonm_outer.ed55 .form-control {
    width: 100%;
  }
  .ed55 .form-group.sim input {
    width: 100%;
    margin: 0 0 5px;
  }
  .ed55 button#countrySubmitButton {
    margin: 7px 0 0;
  }
  .admin_fonm_outer.ed55 {
    padding: 0px 10px;
  }
  .ed9 .dashboard_title.user_single_anchor {
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: wrap;
  }
  .dashboard_title.ed9 + .dashboard_title_btn.user_dashboard_title_btn {
    text-align: left;
  }
  .dashboard_title.ed9 {
    width: 100%;
  }
  .discussion-btn-wrapper {
    display: flex;
    width: 100%;
  }
  .detail-right-btns {
    min-width: 220px;
  }
  .right-detail-wrap .product_right_detail.new_cs {
    width: calc(100% - 220px);
  }
  .detail-right-btns .btn {
    padding: 0px 12px;
  }
  .dashboard_title.report-client-title {
    width: 100%;
  }
  .escorts-row .gallery-item.qw {
    max-width: calc(50% - 15px);
  }
  .admin-wrapper-main .codedruns_outer .right_content .top_header {
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cssw1 .admin-wrapper-main .codedruns_outer .let_menu_bar {
    width: 70px;
  }
  .admin-wrapper-main .codedruns_outer .let_menu_bar {
    width: 0px;
    top: 60px;
    transition: all 0.4s;
  }
  .admin-wrapper-main .codedruns_outer .right_content .top_header .top_button {
    min-width: unset;
  }
  .admin-wrapper-main .let_menu_bar ul li.first_menu.close_btn {
    display: none;
  }
  .dashboard-discussion-wrap .dashboard_title a {
    white-space: nowrap;
  }
  .admin-wrapper-main .m-l-advance-search.col-12 {
    padding: 0;
  }
  .admin-wrapper-main .secure-escort-listing .table_common {
    margin-inline: 0px;
  }
  .admin-wrapper-main .secure-escort-listing {
    padding: 0;
  }
  .dashboard-discussion-wrap .dashboard_title {
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .coms_like.yy.discussion_detils_6.bt_btn {
    justify-content: flex-start;
    display: flex;
    margin: 0;
  }
  .detail-right-btns {
    width: 100%;
    margin-top: 20px;
  }
  .right-detail-wrap .product_right_detail.new_cs {
    width: 100%;
  }
  .right-detail-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  .card_five,
  .card_four {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .edit_profile_outer .about_details li {
    width: 100% !important;
  }
  .shop_custom_tab .nav-tabs .nav-link {
    padding: 12px 6px 16px;
    font-size: 14px;
    width: 95px;
  }
  ul.service_list li {
    width: 100%;
  }
  .discussions_tab .nav-link {
    width: 75px;
    font-size: 13px;
  }
  .shop_custom_tab .nav-tabs .nav-link {
    width: 95px;
  }
  .setting_btn_outer li {
    width: 100%;
  }
  #gallery .nav-tabs .nav-item .nav-link {
    padding: 0 8px;
    font-size: 12px;
    line-height: 30px;
    width: 100%;
  }
  #gallery .nav-tabs .nav-item {
    margin-right: 4px;
    margin-bottom: 10px;
  }
  #gallery .nav-tabs .nav-item:last-child {
    margin-right: 0px;
  }
  .testimonial-modal .modal-dialog {
    max-width: 90%;
  }
}

@media (max-width: 420px) {
  .header_logo {
    width: 90px;
  }
  .top_button {
    width: calc(100% - 138px);
  }
  .top_button .btn {
    width: 64px;
    font-size: 12px;
    line-height: 25px;
    padding: 0 2px;
  }
  .classified_ads {
    display: inline-block;
  }
  .classified_img {
    width: 100%;
    margin-bottom: 16px;
  }
  .classified_detail {
    width: 100%;
  }
  .classified_text {
    padding-top: 10px !important;
  }
  .shop_custom_tab .nav-tabs .nav-link {
    width: 70px;
  }
  .profile_menu li {
    width: 100%;
  }
  .shop_custom_tab.shop_tab_newchanges1 .tab-content {
    padding: 10px;
  }
  .escorts-row .gallery-item.qw {
    max-width: 100%;
  }
  .contry-title .dashboard_title h2 {
    font-size: 18px;
  }
}
@media (max-width: 360px) {
  .coms_like.yy.discussion_detils_6.bt_btn a {
    margin: 0 0 4px;
    width: 100%;
  }
  .coms_like.yy.discussion_detils_6.bt_btn {
    justify-content: flex-start;
    display: flex;
    margin: 0 0 0;
    flex-wrap: wrap;
  }
  #gallery .nav-tabs .nav-item .nav-link {
    padding: 0 5px;
    font-size: 11px;
  }
}
