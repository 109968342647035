.custom-form-input :where(input, textarea) {
  border-radius: 0;
  background: var(--secondary-color);
}

.custom-form-input .form-label {
  font-size: 12px;
  font-weight: bold;
  opacity: 0.6;
}

.input-file input {
  display: none;
}
.form-check-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.form-check-input:focus:active {
  box-shadow: none;
}
.input-file,
.input-file label {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}
.default-input input {
  border-radius: 0;
}
input:where(:active, :focus) {
  box-shadow: none;
}
.form-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  opacity: 0.6;
  margin-bottom: 8px;
}

.form-select {
  border: 1px solid #d4d4d4;
  min-width: 100px;
  border-radius: 8px;
}

.form-select:focus {
  box-shadow: none;
}
.ant-select {
  width: 100%;
  height: unset;
}
.ant-select:where(.css-dev-only-do-not-override-1adbn6x).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  padding: 0.2rem 11px;
  border-radius: 8px;
}
.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
  outline: none;
}
.ant-select .rc-virtual-list-holder .ant-select-item-option-active {
  background-color: #;
}
